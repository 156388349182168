
$menuItems: 2;

$cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);


.header {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    background: rgba(#024419,0.8);
    overflow: hidden;
    transition: all 0.5s ease-out /* background 1s ease-out */;
    transition-delay: 0.2s;
    z-index: 1;
    position: fixed;


    .burger-container {
        position: relative;
        display: inline-block;
        height: 50px;
        width: 100%;
        cursor: pointer;
        transform: rotate(0deg);
        transition: all 0.3s $cubic;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        display: flex; 
        justify-content: flex-end;

        #burger {
            width: 18px;
            height: 8px;
            position: relative;
            display: block;
            margin: -4px auto 0;
            //margin-right: 30px;
            margin: -4px 30px auto 0;
            top: 50%;
            .bar {
                width: 100%;
                height: 2px;
                display: block;
                position: relative;
                background: #FFF;
                transition: all 0.3s $cubic;
                transition-delay: 0s;
                &.topBar {
                    transform: translateY(0px) rotate(0deg);
                }
                &.btmBar {
                    transform: translateY(6px) rotate(0deg);
                }
            }
        }
    }


    ul.menu {
        position: relative;
        display: none;
        padding: 0px 48px 0;
        list-style: none;
        li.menu-item {
            border-bottom: 1px solid #ffffff;
            margin-top: 5px;
            transform: scale(1.15) translateY(-30px);
            opacity: 0;
            transition: transform 0.5s $cubic, opacity 0.6s $cubic;
            @for $i from 1 through $menuItems {
                &:nth-child(#{$i}) {
                    transition-delay: #{0.56 - ($i * 0.07)}s;
                }
            }
            a {
                display: block;
                position: relative;
                color: #FFF;
                //font-family: $mukta;
                //font-weight: 100;
                text-decoration: none;
                font-size: 2rem;
                line-height: 2.35;
                font-weight: 200;
                width: 100%;
            }
        }
    }

    
    &.menu-opened {

        ul.menu{
            display: block;
        }
        display: block;
        height: 100%;
        background-color: #012f08;
        transition: all 0.3s ease-in/* , background 0.5s ease-in */;
        transition-delay: 0.25s;
        .burger-container { 
            //transform: rotate(90deg);
            #burger {
                .bar {
                    transition: all 0.4s $cubic;
                    transition-delay: 0.2s;
                    &.topBar {
                        transform: translateY(4px) rotate(45deg);
                    }
                    &.btmBar {
                        transform: translateY(3px) rotate(-45deg);
                    }
                }
            }
        }
        ul.menu {
            li.menu-item {
                transform: scale(1) translateY(0px);
                opacity: 1;
                @for $i from 1 through $menuItems {
                    &:nth-child(#{$i}) {
                        transition-delay: #{0.07 * $i+0.2}s;
                    }
                }
            }
        }
    }
}