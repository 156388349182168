
.visiter {
    position: relative;
    display: block;

    .content {
        padding: 67px 4% 0;
        width: 100%;
        margin-bottom: 5rem;
        

        @include min($bp-tablet-px){ 
            max-width: 1000px;
            margin: 0 auto;
            width: unset;
        }

        h1{
            font-family: 'glimmer_of_lightregular';
            text-align: center;
            margin-bottom: 30px;
            
            font-size : font-clamp(35, 40, $bp-tablet, $bp-large)
        }

        h2 {
            font-size : font-clamp(30, 35, $bp-tablet, $bp-large)
        }

        h3 {
            font-size: font-clamp(25, 30, $bp-tablet, $bp-large);
            text-align: left;
            margin-bottom: 1rem;
        }

        p {
            font-family: "Ek Mukta",sans-serif;
            font-size: font-clamp(17, 22, $bp-tablet, $bp-large);
            margin-bottom: 1rem;
        }


        .visit.argenton {
            width: 100%;
            margin-top: 20px;
            max-width: 500px;
            max-height: 500px;
            object-fit: contain;
        }


        .visit-box {
            margin: 2rem 1rem ;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            //align-content: center;

            a {
                font-size: 1.2rem;
                text-decoration: none;
                color: #012f08;
                position: relative;
                //margin-left: 15px;
                margin-top: 10px;
            }

            a::before {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 1px;
                bottom: 0;
                left: 0;
                background-color: #012f08;
                transform: scaleX(0);
                transition: transform 0.3s ease;
              }
              
              a:hover::before {
                transform: scaleX(1);
              }
        }
    }
}
